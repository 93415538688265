$primary: #827E7A;
$secondary: #25221E;
$light: #f4f4f4;
$danger: #ea4b35;
$success: #226F54;
$warning: #FF8811;
$font-family-sans-serif: 'Poppins', sans-serif;
@import "~bootstrap/scss/bootstrap";

body {
  font-family: 'Raleway', sans-serif;
  background-color: #E5E5E5;
}

.fondmenu {
  background-color: #25221E;
  padding-top: 5px;
  padding-bottom: 5px;
  /*transition: padding-top .3s,padding-bottom .3s;
  transition-property: padding-top, padding-bottom;*/
  transition-duration: 0.3s;
  transition-timing-function: ease;
}

.teamfond {
  background-image: linear-gradient(rgba(0,0,0, 0.3), rgba(0,0,0, 0.3)),
  url(/images/background-aden.jpg);
  background-repeat: no-repeat,no-repeat;
  background-position-x: center;
  background-size: cover;
  height: 118vh;
}

.tribunalfond {
  background-image: url(/images/tribunal-commerce.jpg);
  background-repeat: no-repeat,no-repeat;
  background-position-x: center;
  background-size: cover;
  background-attachment: fixed;
  height: 20vh;
}

.tribunalparisfond {
  background-image: url(/images/tribunal-commerce-paris.jpg);
  background-repeat: no-repeat,no-repeat;
  background-position-x: center;
  background-size: cover;
  background-attachment: fixed;
  height: 20vh;
}

/*.policebrand {
  font-size: 1.5em;
}

.positionintropage {
  padding-bottom: 230px;
}*/

.fondbloctexte {
  max-width: 100%;
}

.decal {
  position: relative;
  top:-18vh;
}

h1,h2,h3,label {
  color: #827E7A;
}

label {
  margin-bottom: 0.35rem;
}

.menufooter {
  padding-top: 35px;
}

.formulaire {
  margin-top: -18vh;
}

hr {
  background-color: #827E7A;
  opacity: 0.5;
}

button:focus {
  outline: 0;
}

.svgaccueil {
  height: 40vh;
}

/*xs*/

@media (min-width: 0) {
  .soustitre {
    font-size: 1.4em;
  }
  .h2xsdevice {
    font-size: 2rem;
  }
}


/*sm*/

@media (min-width: 576px) {
  .navbar-toggler {
    font-size: 1.2rem;
  }
  .h2xsdevice {
    font-size: 2.5rem;
  }
}

/*md*/

@media (min-width: 768px) {

}

/*lg*/

@media (min-width: 992px) {
  .soustitre {
    font-size: 2em;
  }
}

/*xl*/

@media (min-width: 1200px) {

}

